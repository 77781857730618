.navbar-container {
	z-index: 9;
	position: fixed;
	display: flex;
	width: 100%;
	height: 10vh;
	background: #fff;
	box-shadow: 0px 5px 36.8px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(12px);
	align-items: center;
	justify-content: space-around;
}

.navbar-options-wrapper {
	display: flex;
	gap: 16px;
}

@font-face {
	font-family: Inter;
	src: url(../../../assets/fonts/Inter-VariableFont_slnt\,wght.ttf);
}
.navbar-options {
	color: #b0b0b0;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.07px;
	cursor: pointer;
}

.selected {
	color: #00b0f5;
}

.navbar-logo {
	width: 15%;
	height: 40px;
}

.hamburger-icon {
	margin-left: 35%;
	display: none;
	flex-direction: column;
	cursor: pointer;
	padding: 10px;
}

.bar {
	width: 24px;
	height: 3px;
	background-color: #000;
	margin: 2.7px 0;
	transition: 0.4s;
}

.menu-open .navbar-options-wrapper {
	display: flex;
	align-items: center;
	padding-top: 55px;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	background: #fff;
	box-shadow: 0px 5px 36.8px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(12px);
	z-index: 10;
	width: 60%;
	transform: translateX(100%);
	transition: transform 0.3s ease-in-out;
}

.menu-open .navbar-options {
	display: block;
	text-align: right;
}

@media only screen and (max-width: 768px) {
	.navbar-container {
		justify-content: space-between;
	}

	.navbar-options-wrapper {
		display: none;
	}

	.hamburger-icon {
		display: flex;
	}

	.menu-open .navbar-options-wrapper {
		transform: translateX(0);
	}
	.bar-open {
		right: 2px;
	}
	.bar-open:nth-child(1) {
		width: 40px;
		z-index: 100;
		transform: rotate(-45deg) translate(-5px, 6px);
	}

	.bar-open:nth-child(2) {
		opacity: 0;
	}

	.bar-open:nth-child(3) {
		z-index: 100;
		width: 40px;
		transform: rotate(45deg) translate(-6px, -6px);
	}
	.navbar-logo {
		width: 27%;
		height: 24px;
	}
	.navbar-container {
		justify-content: space-around;
	}
}
@media only screen and (max-width: 325px) {
	.navbar-logo {
		width: 32%;
	}
	.bar-open:nth-child(1) {
		width: 27px;
		z-index: 100;
		transform: rotate(-45deg) translate(-5px, 6px);
	}
	.bar-open:nth-child(3) {
		z-index: 100;
		width: 27px;
		transform: rotate(45deg) translate(-6px, -6px);
	}
}
@media only screen and (max-width: 375px) {
	.navbar-logo {
		width: 32%;
	}
	.bar-open:nth-child(1) {
		width: 27px;
		z-index: 100;
		transform: rotate(-45deg) translate(-5px, 6px);
	}
	.bar-open:nth-child(3) {
		z-index: 100;
		width: 27px;
		transform: rotate(45deg) translate(-6px, -6px);
	}
}
@media only screen and (max-width: 425px) {
	.navbar-logo {
		width: 32%;
	}
	.bar-open:nth-child(1) {
		width: 27px;
		z-index: 100;
		transform: rotate(-45deg) translate(-5px, 6px);
	}
	.bar-open:nth-child(3) {
		z-index: 100;
		width: 27px;
		transform: rotate(45deg) translate(-6px, -6px);
	}
}
@media only screen and (max-width: 500px) {
	.navbar-logo {
		width: 32%;
	}
	.bar-open:nth-child(1) {
		width: 30px;
		z-index: 100;
		transform: rotate(-45deg) translate(-5px, 6px);
	}
	.bar-open:nth-child(3) {
		z-index: 100;
		width: 30px;
		transform: rotate(45deg) translate(-6px, -6px);
	}
}
