* {
    box-sizing: border-box;
}

.input_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00B0F5;
}
.input_content_container {
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25vh 50px 150px 50px;
}
.input_description {
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.input_field {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.input_description_logo {
    width: 100%;
}
.input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2px;
}
.input_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input_group div {
    width: 47%;
}
.input_group_label {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 7px 0;
}
.input_group_input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;
}
.input_group_input::placeholder, .input_select_placeholder {
    color: #CECECE;
}
.input_button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #fff;
    outline: none;
    background-color: #00B0F5;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.input_button svg path {
    transition: all 0.3s ease-in-out;
}
    
.input_button_icon {
    margin-right: 10px;
}
.input_button:hover {
    background-color: #fff;
    color: #00B0F5;
}
.input_button:hover svg path {
    fill: #00B0F5;
}
.input_description_text_title {
    font-size: 48px;
    font-weight: 700;
}
.input_description_text {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 14px 0 0 20px;
}
.input_button_spinner {
    height: 90%;
}
.input_button_loading {
    background-color: #fff;
}
.input_group_error {
    width: 100% !important;
    height: 30px;
}
.input_group_error p{
    color: rgb(217, 47, 91);
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
}
@media screen and (max-width: 768px) {
    .input_content_container {
        width: 90%;
        flex-direction: column;
        margin: 15vh 40px 40px 40px;
    }
    .input_description {
        width: 100%;
    }
    .input_description_text {
        margin: 0;
    }
    .input_field {
        width: 100%;
    }
    .input_group {
        flex-direction: column;
    }
    .input_group div {
        width: 100%;
    }

}
@media screen and (max-width: 1300px) {
    .input_content_container {
        width: 85%;
    }
}