
.input_group_input_textarea {
    height: 150px;
    resize: none;
    padding: 13px 10px;
    font-family: Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 1050px) {

}