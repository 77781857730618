.home-header-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-image: url('../../../assets/images/home-header-image.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 90vh;
	flex-direction: column;
	padding-top: 10vh;
}

.home-header-image {
	width: 100%;
	position: absolute;
}

.home-header-logo {
	position: relative;
	align-self: center;
}

.home-header-text {
	color: #fff;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.about-khd {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 57vh;
}

.about-khd-text-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.about-khd-logo {
	width: 113px;
	height: 113px;
	margin-top: 8%;
}

.about-khd-title {
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.about-khd-bigtxt {
	color: var(--Neutral-600, #475467);
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.underline-text {
	color: #00b0f5;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

.about-khd-text {
	width: 35%;
	text-align: center;
}

.about-khd-button {
	margin-top: 3%;
	margin-bottom: 5%;
	width: 10%;
	height: 48px;
	border-radius: 12px;
	background: #00b0f5;
	padding: 12px;
	align-items: center;
	gap: 8px;
	border: none;
	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
	cursor: pointer;
}

.about-khd-btn-icon {
	margin-left: 8px;
}

.video-section {
	width: 100%;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #00b0f5;
}

.video-section-header {
	margin-top: 6%;
	display: flex;
	justify-content: center;
}

.video-section-logo {
	margin-right: 5%;
	width: 13%;
	height: 20vh;
}

.video-section-text-wrapper {
	width: 26%;
}

.video-section-text {
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.video-wrapper {
	margin-top: 66px;
	margin-bottom: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 50vh;
	background-color: #fff;
}

.video {
	border: none;
	height: 43vh;
}

.map-section {
	background-color: #f7f7f7;
	width: 100%;
}

.map-section-main {
	display: flex;
}

.map-title {
	margin-bottom: 20px;
	padding-top: 70px;
	margin-top: 0px;
	color: #000;
	text-align: center;
	font-family: Inter;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.map-boldtext {
	color: #000;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
}

.map-text {
	margin-left: 14%;
}

.map-text2 {
	margin-top: -3px;
	color: #000;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%;
}

.locationDot {
	width: 15px;
	height: 20px;
}

.map-location-wrapper {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	flex-direction: row;
}

.gap {
	margin-top: 10px;
	margin-left: 11px;
}

/* Add this to your home.css or mobile.css */
.fullscreen-video-container {
	position: relative;
	height: 100vh;
	overflow: hidden;
}

.fullscreen-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8); /* Adjust the opacity as needed */
}

.home-header-text-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: white;
}

.map-bigtext {
	margin-bottom: 20px;
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.map-bigtext-wrapper {
	width: 70%;
	margin-bottom: 32px;
}

.map-wrapper {
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 501px;
	background-color: #fff;
	margin-bottom: 90px;
}

.map-image {
	height: 469px;
}

.map-right-side {
	width: 100%;
}

.map-button {
	border-radius: 12px;
	background: #00b0f5;
	color: #fff;
	border: none;
	cursor: pointer;
	padding: 12px;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.sponsor-section {
	width: 100%;
	height: 377px;
	display: flex;
	align-items: center;
	background-color: #00b0f5;
}

.sponsor-title {
	color: #fff;
	font-family: Inter;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

.sponsor-text {
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.sponsor-button {
	margin-top: 32px;
	border-radius: 12px;
	background: #fff;
	display: flex;
	padding: 12px;
	align-items: center;
	gap: 8px;
	color: #00b0f5;
	border: none;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
	margin-bottom: 48px;
	cursor: pointer;
}

.sponsor-text-main {
	width: 65%;
}

.sponsor-left-side {
	margin-left: 14%;
}

.bottom-img {
	width: 528.873px;
	height: 343.52px;
}

.footer {
	width: 100%;
	height: 100px;
	background-color: #1a2651;
	display: flex;
	align-items: center;
}

.footer-text {
	color: #485174;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin-top: 0px;
	padding-left: 14%;
}
.drugi-text {
	margin-top: 10px;
}
.gap2{
	margin-left: 9px;
}
