* {
	margin: 0;
}

.partners-header {
	background-color: #00b0f5;
	width: 100%;
	height: 400px;
	display: flex;
	align-items: center;
}

.partners-header-text {
	margin-top: 105px;
	margin-left: 14%;
	font-size: 48px;
	font-weight: 700;
	line-height: 72px;
	letter-spacing: 0em;
	width: 29%;
	color: #fff;
}

.header-image {
	margin-bottom: 20px;
	height: 262px;
	align-self: end;
	margin-left: 13%;
}

.partners-main-section {
	width: 100%;
	background-color: #f7f7f7;
	padding-left: 14%;
}

.partners-image {
	width: 220px;
	cursor: pointer;
	height: 220px;
}

.partners-image2 {
	width: 164px;
}

.partners-main-section-title {
	padding-top: 80px;
	padding-bottom: 40px;
	font-size: 40px;
	font-weight: 700;
	line-height: 60px;
	letter-spacing: 0em;
}

.partners-main-section-lastdiv {
	padding-bottom: 80px;
}
@media only screen and (max-width: 900px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		align-self: center;
		margin-left: 0%;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
}
@media only screen and (max-width: 850px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		align-self: center;
		margin-left: 0%;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
}

@media only screen and (max-width: 768px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		align-self: center;
		margin-left: 0%;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
}

@media only screen and (max-width: 550px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 250px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		width: 319px;
		font-size: 36px;
	}
	.partners-image {
		margin-right: 65px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 314px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0% !important;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media screen and (max-width: 780px){
	.general-sponsores-container {
		width: 100% !important;
		display: flex;
		flex: 1 !important;
	}
	.general-sponsores-link {
		display: flex;
		width: 60% !important;
		justify-content: center;
		align-items: center;

	}
}

@media only screen and (max-width: 500px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}
	.header-image {
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
		height: 235px;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		width: 319px;
		font-size: 36px;
	}
	.partners-image {
		margin-right: 57px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 314px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media only screen and (max-width: 475px) {
	.general-sponsores-container {
		width: 100% !important;
	}
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 220px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
	.partners-image {
		margin-right: 49px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 307px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (max-width: 450px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 210px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		font-size: 42px;
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
	.partners-image {
		margin-right: 49px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 307px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.partners-main-section {
		padding-left: 10%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (max-width: 400px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 190px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		font-size: 42px;
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
	.partners-image {
		margin-right: 49px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 265px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.partners-main-section {
		padding-left: 10%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (max-width: 375px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 165px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		font-size: 41px;
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
	.partners-image {
		margin-right: 49px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 265px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.partners-main-section {
		padding-left: 10%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media only screen and (max-width: 325px) {
	.partners-header {
		flex-direction: column-reverse;
		height: 645px;
		align-items: unset;
	}

	.header-image {
		height: 155px;
		align-self: center;
		margin-left: 0%;
		margin-bottom: 0px;
	}
	.partners-header-text {
		font-size: 41px;
		width: 55%;
		margin-top: 55px;
		margin-bottom: 50px;
	}
	.partners-main-section-title {
		font-size: 36px;
	}
	.partners-image {
		margin-right: 49px;
	}
	.sponsor-text-main {
		width: 92%;
	}
	.bottom-img {
		width: 100%;
		height: 244px;
	}
	.partners-header-text {
		margin-top: 30px;
		width: 75%;
	}
	.partners-main-section {
		padding-left: 10%;
	}
	.images-parent {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 60px;
	}
	.partners-main-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 0%;
	}
	.partners-main-section-title {
		width: 200px;
		text-align: center;
	}
	.partners-image {
		margin-bottom: 48px;
	}
	.partners-main-section-lastdiv {
		margin-left: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.school-logo {
	width: 210px !important;
}

.partner-logo {
	max-width: 100%;
	max-height: 100%;
	border-radius: 10px;
}

.partner-logo-small {
	max-width: 100px;
	max-height: 100%;
}

.partner-logo-medium {
	max-width: 190px;
	max-height: 100%;
}

.partners-logo-container {
	cursor: pointer;
	width: 230px;
	height: 230px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	transition: transform 0.5s ease;
  }

  .partners-section {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
  }

  @media (max-width: 768px) {
	.partners-section {
	  justify-content: center;
	}
	.partners-section-container {
	  align-items: center;
  }
  .partners-main-section {
	padding-left: 0% !important;
  }
}

  .partners-section-container {
	display: flex;
	flex-direction: column;
  }

  .general-sponsores-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40%;
	background-color: #fff;
	border-radius: 10px;
  }

  .partners-logo-container:hover {
	transform: scale(1.1);
  }

  .general-sponsor-logo {
	object-fit: contain;
	max-width: 100%;
  }