.aboutUsFooterCopyrightContainer{
    padding: 5vh 15vw;
    background: #1A2651;
}
.aboutUsFooterCopyrightText{
    color: #696969;
    margin: 0;
    text-align: start;
    display: flex;
    align-items: center;
}
.aboutUsFooterCopyrightIcon{
    margin-right: 0.5rem;
    fill: #707070;
}
@media only screen and (max-width:768px){
    .aboutUsFooterCopyrightText{
        font-size:0.7rem;
    }
    .aboutUsFooterCopyrightIcon{
        width: 0.7rem;
    }
}