body {
	font-family: Arial, Helvetica, sans-serif;
}
.aboutUsContainer::before {
	content: ' ';
	display: block;
	height: 10vh;
}
.aboutUsHeaderContainer {
	width: 100vw;
	background-color: #00b0f5;
	position: relative;
	display: flex;
	justify-content: flex-end;
}
.aboutUsHeaderImage {
	width: 60%;
	height: auto;
}
.aboutUsHeaderGradient {
	background: linear-gradient(
		90deg,
		#00b0f5 0%,
		rgba(0, 176, 245, 0) 100%
	);
	height: 100%;
	width: 33vw;
	left: 40vw;
	position: absolute;
}
.aboutUsHeaderText {
	z-index: 5;
	box-sizing: border-box;
	position: absolute;
	top: 30%;
	left: 15%;
	display: flex;
	flex-direction: column;
	width: 40%;
	color: white;
	justify-content: center;
}
.aboutUsHeaderTitle {
	font-size: 4vw;
	margin: 0;
	text-align: start;
}
.aboutUsHeaderSubtitle {
	margin: 0;
	text-align: start;
	font-size: 3vw;
	font-weight: 200;
}
.aboutUsInformationContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem 0;
	background-color: #f7f7f7;
}
.aboutUsInformationText {
	width: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
}
.aboutUsInformationImage {
	width: 25%;
	margin-left: 3rem;
}
.aboutUsInformationParagraph {
	text-align: start;
	line-height: 21px;
}
.aboutUsInformationButton {
	border: 0;
	color: white;
	font-size: 1rem;
	font-weight: 500;
	border-radius: 0.5rem;
	background-color: #00b0f5;
	display: flex;
	align-items: center;
	padding: 0.5rem;
	margin-top: 1.5rem;
	cursor: pointer;
}
.aboutUsInformationTextBolded {
	font-weight: 800;
}
.aboutUsInformationTextLink {
	font-weight: 800;
	color: #00b0f5;
	text-decoration: underline;
}
.aboutUsGalleryContainer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 2rem;
	padding: 10vh 15vw;
}
.aboutUsGalleryImageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 31px 30.3px -23px #00000040;
	border-radius: 10px;
	cursor: pointer;
	transition: transform 0.5s ease;
}
.aboutUsGalleryImageContainer:hover {
	transform: scale(1.1);
}
.aboutUsGalleryImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}
.aboutUsFooterContainer {
	background-color: #00b0f5;
	padding: 10vh 15vw 0 15vw;
	display: flex;
}
.aboutUsFooterText {
	width: 50%;
	color: white;
	text-align: start;
}
.aboutUsFooterImage {
	width: 50%;
}
.aboutUsFooterTitle {
	font-size: 3.5vw;
	margin-top: 0;
}
.aboutUsFooterParagraph {
	line-height: 21px;
	margin-bottom: 2rem;
}
.aboutUsFooterButton {
	background-color: white;
	border: 0;
	color: #00b0f5;
	font-weight: 800;
	padding: 1rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	font-size: 1rem;
}
.aboutUsFooterButtonText {
	margin-left: 0.5rem;
}

@media only screen and (max-width: 767px) {
	.aboutUsHeaderImage {
		width: 100vw;
		height: 60vh;
		object-fit: cover;
		padding-bottom: 15vh;
	}
	.aboutUsHeaderContainer {
		flex-direction: column;
	}
	.aboutUsHeaderGradient {
		width: 100%;
		height: 100vw;
		rotate: -90deg;
		bottom: 15vh;
		left: 0;
	}
	.aboutUsHeaderText {
		top: 50%;
		width: 90%;
		left: 5vw;
	}
	.aboutUsHeaderTitle {
		font-size: 7vh;
	}
	.aboutUsHeaderSubtitle {
		font-size: 5vh;
	}
	.aboutUsInformationContainer {
		flex-direction: column-reverse;
		align-items: center;
	}
	.aboutUsInformationText {
		width: 80%;
		align-items: center;
	}
	.aboutUsInformationParagraph {
		text-align: center;
	}
	.aboutUsInformationImage {
		margin: 0;
		width: 50%;
	}
	.aboutUsGalleryContainer {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.aboutUsFooterContainer {
		flex-direction: column;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
		align-items: center;
	}
	.aboutUsFooterText {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.aboutUsFooterParagraph {
		text-align: center;
	}
	.aboutUsFooterTitle {
		font-size: 5vh;
		text-align: center;
	}
	.aboutUsFooterImage {
		width: 100%;
	}
}
