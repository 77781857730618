@media only screen and (max-width: 325px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 375px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 425px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}
	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 450px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 475px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 500px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 550px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}

	.home-header-logo {
		width: 77%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 155px;
		height: 130px;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 221px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 768px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}
	.home-header-text {
		margin-top: 40px;
	}
	.home-header-logo {
		width: 58%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 40%;
		height: 10%;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 345px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 850px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}
	.home-header-text {
		margin-top: 40px;
	}
	.home-header-logo {
		width: 58%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 40%;
		height: 10%;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 345px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
@media only screen and (max-width: 1000px) {
	.home-header-container {
		background-image: url('../../../assets/images/home-header-image-mobile.png');
	}
	.home-header-text {
		margin-top: 40px;
	}
	.home-header-logo {
		width: 58%;
	}

	.about-khd-text {
		width: 77%;
	}

	.about-khd-button {
		width: 44%;
	}

	.video-section-header {
		flex-direction: column;
		align-items: center;
	}

	.video-section-logo {
		margin-top: 80px;
		margin-bottom: 45px;
		width: 40%;
		height: 10%;
	}

	.video-section-text-wrapper {
		width: 77%;
	}

	.video-wrapper {
		width: 85%;
		height: 45vh;
	}

	.map-section-main {
		flex-direction: column-reverse;
	}

	.map-right-side {
		display: flex;
		justify-content: center;
	}

	.map-image {
		height: 469px;
		width: 88%;
object-fit: cover;
	}
	.map-bigtext-wrapper {
		width: 77%;
	}
	.map-button {
		margin-bottom: 80px;
	}
	.sponsor-section {
		height: 810px;
		flex-direction: column;
	}
	.sponsor-text-main {
		width: 100%;
	}
	.sponsor-left-side {
		margin-left: 8%;
		width: 86%;
	}
	.sponsor-title {
		margin-top: 80px;
		text-align: left;
	}
	.bottom-img {
		width: 100%;
		height: 345px;
	}
	.sponsor-button {
		margin-bottom: 74px;
	}
}
